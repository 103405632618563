<template>
  <!-- FOLLOW DESIGN 07/06/2024 -->
  <div>
    <AppCollapse
      type="border-info-2 card"
      class="mb-50"
    >
      <AppCollapseItem
        is-visible
        title
        class-header="card-header bg-blue-gradient p-0 m-50"
        class="border-info-2"
      >
        <template #header>
          <div class="d-flex-center justify-content-start gap-1">
            <b-img
              src="@icons/dollar.svg"
              class="icon-card"
              width="50px"
            />
            <h6 class="text-airline font-medium-3 m-0 py-50 fw-700">
              {{ $t('reservation.priceDetails.header') }}
            </h6>
          </div>

          <div
            v-if="!isHideFunctionButton && !hideFeature && reservationsData.status !== 'CANCEL'"
            :class="isMobileView ? 'd-flex-center flex-wrap' : ''"
            style="margin-right: 2.5rem;"
          >
            <b-button
              v-if="isShowToggleServiceFee"
              v-b-tooltip.hover.window
              variant="flat-primary"
              class="p-50 rounded-circle mx-25"
              :title="`${$t(`reservation.${showServiceFee ? 'Hide' : 'Show'}`)} ${$t('reservation.priceDetails.titleTable.feeService')}`"
              @click.prevent.stop="showServiceFee = !showServiceFee"
            >
              <img
                v-if="showServiceFee"
                src="@icons/money.svg"
              >
              <img
                v-else
                src="@icons/not_money.svg"
              >
            </b-button>
            <b-button
              v-else
              v-b-tooltip.hover.window
              variant="flat-primary"
              class="p-50 rounded-circle mx-25"
              :title="`${$t(`reservation.${showServiceFee ? 'Hide' : 'Show'}`)} ${$t('reservation.priceDetails.titleTable.agencyFee')}`"
              @click.prevent.stop="showAgencyfee = !showAgencyfee"
            >
              <img
                v-if="showAgencyfee"
                src="@icons/money.svg"
              >
              <img
                v-else
                src="@icons/not_money.svg"
              >
            </b-button>
          </div>
        </template>
        <BAlert
          v-if="reservationsData.status === 'CANCEL'"
          variant="danger"
          show
          class="px-2 py-1"
        >
          {{ $t('reservation.bookingCancelled') }}
        </BAlert>

        <b-card-body
          v-else
          class="d-flex flex-column gap-3 py-25 px-0"
        >
          <!-- SECTION Giá vé -->
          <!-- ANCHOR paxFareInfoTicket -->
          <div
            v-if="reservationsData.paxFareInfoTicket && reservationsData.paxFareInfoTicket.length"
            class="d-flex flex-column gap-2"
          >
            <b-card
              v-for="(paxFareInfoTicketItem, paxFareInfoTicketIndex) of reservationsData.paxFareInfoTicket"
              :key="paxFareInfoTicketIndex"
              header-bg-variant="transparent"
              header-class="mb-0 p-50 justify-content-center cursor-none"
              body-class="pb-0 px-0"
              class="border-secondary"
            >
              <template #header>
                <div class="fw-600 mb-0 d-flex overflow-auto gap-1 text-warning font-medium-3 ">
                  <!-- <div v-if="!isMobileView">
                    {{ $t('flight.flightSegment') }}:
                  </div> -->

                  <!-- NOTE - Bỏ theo yêu cầu (https://discord.com/channels/1054696448110903327/1316688713320890408) -->
                  <!-- <template v-if="['VN1A', 'VN1A_MT', '1A'].includes(reservationsData.source)">
                    <div class="d-flex">
                      <div
                        v-for="(segment, segmentIndex) of paxFareInfoTicketItem?.trips"
                        :key="segmentIndex"
                        :class="`pr-50 mr-50 ${segmentIndex !== (paxFareInfoTicketItem?.trips.length - 1) ? 'border-right-secondary' : ''}`"
                      >
                        <div class="text-center fw-800 text-nowrap">
                          {{ segment.departure }} - {{ segment.arrival }}
                        </div>
                        <span
                          v-if="['VN1A', 'VN1A_MT'].includes(reservationsData.source)"
                          :class="`fw-700 text-nowrap ${isPaidSegment(reservationsData.itineraries, segment) ? 'text-success' : 'text-warning'}`"
                        >
                          {{ isPaidSegment(reservationsData.itineraries, segment) ? $t('reservation.PAID') : $t('reservation.UNPAID') }}
                        </span>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    {{ resolveTrip(paxFareInfoTicketItem?.trips) }}
                  </template> -->

                  {{ resolveTrip(paxFareInfoTicketItem?.trips) }}
                </div>
              </template>

              <b-table
                centered
                responsive
                small
                class="mb-0 border"
                thead-class="text-dark align-middle text-nowrap text-right"
                tbody-class="text-dark align-middle text-nowrap"
                :fields="tableColumnsPaxFareInfoTicket"
                :items="resolvePaxFareInfoTicket(paxFareInfoTicketItem)"
              >
                <template
                  v-for="(column, indexColumn) in tableColumnsPaxFareInfoTicket"
                  #[`head(${column})`]="data"
                >
                  <span :key="indexColumn">
                    {{ $t(`reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.${data.column}`) }}
                  </span>
                </template>
                <template #cell(passenger)="data">
                  <div class="text-right font-weight-bolder">
                    <span>{{ $te(`reservation.${data.item.paxType}`) ? $t(`reservation.${data.item.paxType}`) : data.item.paxType }}{{ data.item?.amountPassenger ? ' x' + data.item.amountPassenger : '' }}</span>
                  </div>
                </template>
                <template #cell(netPrice)="data">
                  <div class="text-right font-weight-bolder">
                    <span>{{ formatCurrency(data.item.netPrice) }}</span>
                  </div>
                </template>
                <template #cell(tax)="data">
                  <div class="text-right font-weight-bolder">
                    <span>{{ formatCurrency(showServiceFee ? (data.item.tax - data.item.feeService) : showAgencyfee ? (data.item.tax - data.item.fee) : data.item.tax) }}</span>
                  </div>
                </template>
                <template #cell(total)="data">
                  <div class="text-right font-weight-bolder">
                    <span>{{ formatCurrency(data.item.total) }}</span>
                  </div>
                </template>
                <template #cell(feeService)="data">
                  <div class="text-right font-weight-bolder">
                    <span>{{ formatCurrency(data.item.feeService) }}</span>
                  </div>
                </template>
                <template #cell(agencyFee)="data">
                  <div class="text-right font-weight-bolder">
                    <span>{{ formatCurrency(data.item.fee) }}</span>
                  </div>
                </template>
              </b-table>

              <div class="font-weight-bolder text-danger text-right my-50 d-flex-center w-100 justify-content-end gap-2">
                <div>
                  <span>{{ $t('reservation.total') }}:</span>
                  <span class="ml-50 text-20px">
                    {{ formatCurrency(sumTotalArrayTicket[paxFareInfoTicketIndex]) }}
                  </span>
                </div>
                <div class="px-1">
                  <feather-icon
                    class="text-warning mr-25"
                    icon="InfoIcon"
                  />
                  <span class="text-nowrap text-italic text-warning font-small-3">{{ $t('flight.fareText') }}</span>
                </div>
              </div>
              <AlertCheckServiceFee v-if="isShowAlertCheckServiceFee" />
            </b-card>
          </div>

          <div v-else-if="(reservationsData.priceQuotes && reservationsData.priceQuotes.length) || (reservationsData.historicalPriceQuotes && reservationsData.historicalPriceQuotes.length)">
            <!-- ANCHOR historicalPriceQuotes -->
            <div v-if="reservationsData.priceQuotes && !reservationsData.priceQuotes.length && reservationsData.historicalPriceQuotes && reservationsData.historicalPriceQuotes.length > 0">
              <BCard
                header-bg-variant="transparent"
                header-class="mb-0 p-50 justify-content-center cursor-none"
                body-class="pb-0 px-0"
                class="border-secondary mb-50"
              >
                <template #header>
                  <div class="fw-600 mb-0 d-flex overflow-auto gap-1 text-warning font-medium-3 ">
                    {{ getPricesByPriceQuotesTicket(reservationsData.historicalPriceQuotes).items.map(i => i.flight).join(', ') }}
                  </div>
                </template>
                <b-table-lite
                  responsive
                  centered
                  thead-class="text-dark align-middle text-nowrap text-right"
                  tbody-class="text-dark align-middle text-nowrap text-right border"
                  :fields="tableColumnsPriceQuotes"
                  :items="getPricesByPriceQuotesTicket(reservationsData.historicalPriceQuotes).items"
                >
                  <template
                    v-for="(column) in tableColumnsPriceQuotes"
                    #[`head(${column})`]="data"
                  >
                    <span :key="column.key">
                      {{ $t(`reservation.priceDetails.titleTable.${data.column}`) }}
                    </span>
                  </template>

                  <template #cell(paxType)="{ item }">
                    <span class="font-weight-bolder">{{ $t(`reservation.${item.paxType}`) }} x{{ item.amount }}</span>
                  </template>

                  <template #cell(flight)="{ item }">
                    <span class="font-weight-bolder">{{ item.flight }}</span>
                  </template>

                  <template #cell(fare)="{ item }">
                    <div class="text-right font-weight-bolder">
                      <span>{{ formatCurrency(item.fare) }}</span>
                    </div>
                  </template>

                  <template #cell(tax)="{ item }">
                    <div class="text-right font-weight-bolder">
                      <span>{{ formatCurrency(item.tax - (showServiceFee ? item.feeService : showAgencyfee ? item.fee : 0)) }}</span>
                    </div>
                  </template>

                  <template #cell(feeService)="{ item }">
                    <div class="text-right font-weight-bolder">
                      <span>{{ formatCurrency(item.feeService) }}</span>
                    </div>
                  </template>

                  <template #cell(agencyFee)="{ item }">
                    <div class="text-right font-weight-bolder">
                      <span>{{ formatCurrency(item.fee) }}</span>
                    </div>
                  </template>

                  <template #cell(total)="{ item }">
                    <div class="text-right font-weight-bolder">
                      <span>{{ formatCurrency(item.total) }}</span>
                    </div>
                  </template>
                </b-table-lite>
                <div class="font-weight-bolder text-danger text-right my-50 d-flex-center w-100 justify-content-end gap-2">
                  <div>
                    <span>{{ $t('reservation.total') }}:</span>
                    <span class="ml-50 text-20px">
                      {{ formatCurrency(getPricesByPriceQuotesTicket(reservationsData.historicalPriceQuotes).total) }}
                    </span>
                  </div>
                  <div class="px-1">
                    <feather-icon
                      class="text-warning mr-25"
                      icon="InfoIcon"
                    />
                    <span class="text-nowrap text-italic text-warning font-small-3">{{ $t('flight.fareText') }}</span>
                  </div>
                </div>
              </BCard>
            </div>
            <!-- ANCHOR priceQuotes -->
            <div v-else-if="reservationsData.priceQuotes && reservationsData.priceQuotes.length > 0">
              <BCard
                header-bg-variant="transparent"
                header-class="mb-0 p-50 justify-content-center cursor-none"
                body-class="pb-0 px-0"
                class="border-secondary mb-50"
              >
                <template #header>
                  <div class="fw-600 mb-0 d-flex overflow-auto gap-1 text-warning font-medium-3 ">
                    {{ getPricesByPriceQuotesTicket(reservationsData.priceQuotes).items.map(i => i.flight).join(', ') }}
                  </div>
                </template>
                <b-table-lite
                  responsive
                  centered
                  thead-class="text-dark align-middle text-nowrap text-right"
                  tbody-class="text-dark align-middle text-nowrap text-right border"
                  :fields="tableColumnsPriceQuotes"
                  :items="getPricesByPriceQuotesTicket(reservationsData.priceQuotes).items"
                >
                  <template
                    v-for="(column) in tableColumnsPriceQuotes"
                    #[`head(${column})`]="data"
                  >
                    <span :key="column.key">
                      {{ $t(`reservation.priceDetails.titleTable.${data.column}`) }}
                    </span>
                  </template>

                  <template #cell(paxType)="{ item }">
                    <span class="font-weight-bolder">{{ $t(`reservation.${item.paxType}`) }} x{{ item.amount }}</span>
                  </template>

                  <template #cell(flight)="{ item }">
                    <span class="font-weight-bolder">{{ item.flight }}</span>
                  </template>

                  <template #cell(fare)="{ item }">
                    <div class="text-right font-weight-bolder">
                      <span>{{ formatCurrency(item.fare ) }}</span>
                    </div>
                  </template>

                  <template #cell(tax)="{ item }">
                    <div class="text-right font-weight-bolder">
                      <span>{{ formatCurrency(item.tax - (showServiceFee ? item.feeService : showAgencyfee ? item.fee : 0)) }}</span>
                    </div>
                  </template>

                  <template #cell(feeService)="{ item }">
                    <div class="text-right font-weight-bolder">
                      <span>{{ formatCurrency(item.feeService ) }}</span>
                    </div>
                  </template>
                  <template #cell(agencyFee)="{ item }">
                    <div class="text-right font-weight-bolder">
                      <span>{{ formatCurrency(item.fee) }}</span>
                    </div>
                  </template>

                  <template #cell(total)="{ item }">
                    <div class="text-right font-weight-bolder">
                      <span>{{ formatCurrency(item.total ) }}</span>
                    </div>
                  </template>
                </b-table-lite>
                <div class="font-weight-bolder text-danger text-right my-50 d-flex-center w-100 justify-content-end gap-2">
                  <div>
                    <span>{{ $t('reservation.total') }}:</span>
                    <span class="ml-50 text-20px">
                      {{ formatCurrency(getPricesByPriceQuotesTicket(reservationsData.priceQuotes).total) }}
                    </span>
                  </div>
                  <div class="px-1">
                    <feather-icon
                      class="text-warning mr-25"
                      icon="InfoIcon"
                    />
                    <span class="text-nowrap text-italic text-warning font-small-3">{{ $t('flight.fareText') }}</span>
                  </div>
                </div>
              </BCard>
            </div>
          </div>

          <!-- ANCHOR fareInfoTicket  -->
          <div v-else>
            <BCard
              header-bg-variant="transparent"
              header-class="mb-0 p-50 justify-content-center cursor-none"
              body-class="p-0 rounded-8px"
              class="border-secondary mb-50"
            >
              <!-- <template #header>
                <div class="fw-600 mb-0 d-flex overflow-auto gap-1 text-warning font-medium-3 ">
                  title
                </div>
              </template> -->
              <b-table-lite
                bordered
                responsive
                centered
                tbody-class="text-dark align-middle"
                :fields="tableColumnsFareInfoTicket"
                class="rounded-8px"
                :items="[reservationsData.fareInfoTicket]"
              >
                <template
                  v-for="(column) in tableColumnsFareInfoTicket"
                  #[`head(${column})`]="data"
                >
                  <span :key="column.key">
                    {{ $t(`reservation.priceDetails.titleTable.${data.column}`) }}
                  </span>
                </template>
                <template #cell(netFare)="{ item }">
                  <div class="text-right font-weight-bolder">
                    <span>{{ formatCurrency(item.netFare) }}</span>
                  </div>
                </template>
                <template #cell(tax)="{ item }">
                  <div class="text-right font-weight-bolder">
                    <span>{{ formatCurrency(showServiceFee ? (item.tax - reservationsData.totalFeeService) : item.tax) }}</span>
                  </div>
                </template>
                <template #cell(feeService)>
                  <div class="text-right font-weight-bolder">
                    <span>{{ formatCurrency(reservationsData.totalFeeService) }}</span>
                  </div>
                </template>
                <template #cell(total)="{ item }">
                  <div class="text-right font-weight-bolder">
                    <span>{{ formatCurrency(item.total) }}</span>
                  </div>
                </template>
              </b-table-lite>
              <div class="font-weight-bolder text-danger text-right my-50 d-flex-center w-100 justify-content-end gap-2">
                <div>
                  <span>{{ $t('reservation.total') }}:</span>
                  <span class="ml-50 text-20px">
                    {{ formatCurrency(reservationsData.fareInfoTicket.total) }}
                  </span>
                </div>
                <div class="px-1">
                  <feather-icon
                    class="text-warning mr-25"
                    icon="InfoIcon"
                  />
                  <span class="text-nowrap text-italic text-warning font-small-3">{{ $t('flight.fareText') }}</span>
                </div>
              </div>
            </BCard>
          </div>
          <!-- !SECTION -->

          <!-- SECTION Giá dịch vụ -->
          <BCard
            header-bg-variant="transparent"
            header-class="mb-0 py-50 px-1 justify-content-start cursor-none"
            body-class="pb-0 px-0"
            class="border-secondary"
          >
            <template #header>
              <div class="fw-600 mb-0 d-flex overflow-auto gap-1 text-body font-medium-3">
                {{ $t('reservation.services') }}
              </div>
            </template>
            <AncillaryServicesComponent
              :reservations-data="reservationsData"
              :is-booking-out-system="isBookingOutSystem"
              :is-hide-function-button="isHideFunctionButton"
              :can-issue-limit="canIssueLimit"
              :is-locked-modify="isLockedModify"
              :total-ancillary-service-price.sync="totalAncillaryServicePrice"
            />

            <b-alert
              v-if="reservationsData.source.includes('1S') && reservationsData.status === 'HOLD' && !isEmpty(reservationsData.ancillaryServices.filter(item => item.status && ['HD'].includes(item.status)))"
              variant="warning"
              class="py-75 px-1 mx-lg-3 mx-sm-1 text-center fw-700"
              show
            >
              <span class="text-danger">Vietnam Airlines: Vui lòng thanh toán hành trình trước khi thanh toán dịch vụ</span>
            </b-alert>
          </BCard>
          <!-- !SECTION -->

          <!-- SECTION Tổng -->
          <div class="d-flex-between">
            <div
              class="px-1 py-50 rounded d-flex-center align-items-start flex-column"
              :style="`border: 1px solid ${showBenefit ? '#E6E6E6' : 'transparent'}; height: 72px`"
            >
              <div>
                <BButton
                  v-if="!hideFeature"
                  variant="primary"
                  class="p-25 rounded-circle"
                  @click="showBenefit = !showBenefit"
                >
                  <feather-icon
                    v-if="showBenefit"
                    icon="EyeIcon"
                    size="16"
                  />
                  <feather-icon
                    v-else
                    icon="EyeOffIcon"
                    size="16"
                  />
                </BButton>
              </div>
              <div v-if="showBenefit">
                <span>{{ $t('reservation.benefit') }}: </span>
                <span :class="`text-${benefit > 0 ? 'success' : 'danger'} ml-50 font-medium-4`">{{ formatCurrency(benefit) }} </span>
              </div>
            </div>
            <div class="font-weight-bolder text-right">
              <div>
                <span class="text-body font-medium-3">{{ $t('reservation.totalCostPrice') }}:</span>
                <span class="text-danger ml-50 font-large-1">
                  {{ formatCurrency(totalAllTicket) }}
                </span>
              </div>
              <div v-if="showServiceFee || showAgencyfee">
                <span class="text-body font-medium-1">{{ $t('reservation.pricePaidToSupplier') }}:</span>
                <span class="text-success ml-50 font-medium-4">
                  {{ formatCurrency(totalAll) }}
                </span>
              </div>

              <!-- ANCHOR số tiền bảo lưu định danh VJ `refundAmountIdentification` -->
              <div
                v-if="reservationsData.source === 'VJ' && reservationsData?.refundAmountIdentification"
                class="d-flex-center justify-content-end"
              >
                <div class="text-nowrap text-warning font-medium-1">
                  {{ $t('reservation.amountRefunded') }}:
                </div>

                <div class="text-right font-weight-bolder font-medium-4 text-warning ml-50">
                  -{{ formatCurrency(reservationsData?.refundAmountIdentification) }}
                </div>
              </div>
            </div>
          </div>
          <!-- !SECTION -->
        </b-card-body>

        <ModalSetServiceFee :reservations-data="reservationsData" />
      </AppCollapseItem>
    </AppCollapse>

    <!-- SECTION button footer -->
    <div
      v-if="!hideFeature && !isHideFunctionButton"
      class="d-flex-center justify-content-end my-50 gap-1 flex-wrap"
    >
      <!-- ANCHOR Thanh toán ancillary VN1A -->
      <!-- <b-button
        v-if="['VN1A', 'VN1A_MT'].includes(reservationsData.source)"
        class="d-flex-center text-nowrap px-75"
        variant="success"
        :disabled="!canAccess('booking.addBag') || isBookingOutSystem || !canIssueLimit || disabledPaymentAncillary(reservationsData)"
        size="sm"
        @click="openPaymentAncillaryServiceHandle(reservationsData)"
      >
        <IAmIcon
          icon="dollar"
          size="16"
        />
        <span class="font-small-4">
          Thanh toán dịch vụ thêm
        </span>
      </b-button> -->

      <b-button
        v-if="isVisibleCustomServiceFee"
        v-b-modal.modal-set-service-fee
        variant="outline-warning"
        :disabled="isBookingOutSystem || !canIssueLimit || reservationsData.status === 'CANCEL'"
        size="sm"
      >
        <feather-icon
          class="d-inline mr-50"
          icon="ToolIcon"
        />
        <span class="font-small-4">{{ $t('reservation.sellingPriceDetails.reSetServiceFee') }}</span>
      </b-button>
    </div>
    <!-- !SECTION -->

    <ModalPaymentAncillary :bookingData="reservationsData" />
  </div>
</template>

<script>
import {
  BCard, BCardBody, BTableLite, BButton, BTable, VBTooltip, BAlert, BImg,
} from 'bootstrap-vue'
import { computed, ref, toRefs } from '@vue/composition-api'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'

import {
  convertISODateTime,
  formatCurrency,
} from '@core/utils/filter'

import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'
import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BCardBody,
    BTableLite,
    BTable,
    BButton,
    BImg,
    BAlert,
    ModalSetServiceFee: () => import('@reservation/reservation-modify/components/detail/components/ModalSetServiceFee.vue'),
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    AncillaryServicesComponent: () => import('@reservation/reservation-modify/components/detail/ancillary-services/index.vue'),
    AlertCheckServiceFee: () => import('./AlertCheckServiceFee.vue'),
    ModalPaymentAncillary: () => import('@reservation/reservation-modify/components/detail/ancillary-services/ModalPaymentAncillary.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    reservationsData: {
      type: Object,
      required: true,
    },
    isBookingOutSystem: {
      type: Boolean,
      default: false,
    },
    isHideFunctionButton: {
      type: Boolean,
      default: false,
    },
    canIssueLimit: {
      type: Boolean,
      default: false,
    },
    isLockedModify: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root }) {
    const { toastWarning } = useToast()
    const { getAirportByAirportCode, isPaidSegment, hideFeature } = useReservationHandle()
    const {
      fnAncillary,
      bookingSource,
      delay,
      getAirlineBySource,
    } = useAncillaryServicesHandle()
    const showServiceFee = ref(false)
    const showAgencyfee = ref(false)

    const tableColumnsFareInfoTicket = computed(() => showServiceFee.value ? ['feeService', 'total'] : showAgencyfee.value ? ['agencyFee', 'total'] : ['total'])
    const tableColumnsPaxFareInfoTicket = computed(() => {
      let arr = ['passenger', 'netPrice', 'tax', 'feeService', 'agencyFee', 'total']
      if (['F1'].includes(bookingSource.value)) {
        arr = arr.filter(item => !['netPrice', 'tax'].includes(item))
      }
      return showServiceFee.value ? arr.filter(item => !item.includes('agencyFee')) : showAgencyfee.value ? arr.filter(item => !item.includes('feeService')) : arr.filter(item => !item.includes('feeService') && !item.includes('agencyFee'))
    })

    const reservationsData = toRefs(props).reservationsData
    const agencyDataId = computed(() => store.getters['userStore/getAgencyDataId'])

    const isShowToggleServiceFee = computed(() => agencyDataId.value && reservationsData?.value?.createdBy?.agency?.id === agencyDataId.value)

    // Nếu code f3 và view f1 thì hiển thị parentFee
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isShowParentAgencyFee = computed(() => {
      const result = isRoleF1.value
      && reservationsData?.value?.createdBy?.agency?.id !== Number(process.env.VUE_APP_MAMA_AGENCY_ID)
      && reservationsData?.value?.createdBy?.agency?.parentAgency?.id !== Number(process.env.VUE_APP_MAMA_AGENCY_ID)
      return result
    })

    // sửa phí dịch vụ đối với những vé đã hold , chỉ áp dụng với người tạo booking đó
    const isVisibleCustomServiceFee = computed(() => {
      // vé đã hold
      const isHold = props.reservationsData.status === 'HOLD'
      // chỉ áp dụng với người tạo booking đó
      const createdId = props.reservationsData?.createdBy?.id
      const meId = computed(() => store.getters['userStore/getMeDataId'])
      const isByCreator = createdId === meId.value
      return isHold && isByCreator
    })

    // convert từ paxInfo là data của từng khách thành data của paxType
    function resolvePaxFareInfoTicket(paxFareInfoTicket) {
      const { paxInfo } = paxFareInfoTicket
      const paxInfoGroup = groupBy(paxInfo, item => item.paxType)
      const res = Object.keys(paxInfoGroup).reduce((result, key) => {
        const amountPassenger = paxInfoGroup[key].length
        result[key] = {
          paxType: key,
          amountPassenger,
          netPrice: paxInfoGroup[key].reduce((netPrice, item) => netPrice + item.netPrice, 0) / amountPassenger,
          discount: paxInfoGroup[key].reduce((discount, item) => discount + item.discount, 0) / amountPassenger,
          discountPostpaid: paxInfoGroup[key].reduce((discountPostpaid, item) => discountPostpaid + item.discountPostpaid, 0) / amountPassenger,
          fee: paxInfoGroup[key].reduce((fee, item) => fee + ((isShowParentAgencyFee.value ? item?.parentFee : item.fee) || 0), 0) / amountPassenger,
          feeService: paxInfoGroup[key].reduce((feeService, item) => feeService + item.feeService, 0) / amountPassenger,
          tax: paxInfoGroup[key].reduce((tax, item) => tax + item.tax, 0) / amountPassenger,
          total: paxInfoGroup[key].reduce((total, item) => total + item.total, 0) / amountPassenger,
        }
        return result // object
      }, {})

      if (['F1'].includes(reservationsData.value.source)) {
        const r = {
          paxType: '',
          netPrice: 0,
          discount: 0,
          discountPostpaid: 0,
          fee: 0,
          feeService: 0,
          tax: 0,
          total: 0,
        }
        Object.values(res).forEach((item, index) => {
          const { amountPassenger } = item
          r.paxType += `${root.$t(`reservation.${item.paxType}`)} x${amountPassenger}${index !== Object.values(res).length - 1 ? ', ' : ''}`
          r.netPrice += item.netPrice * amountPassenger
          r.discount += item.discount * amountPassenger
          r.discountPostpaid += item.discountPostpaid * amountPassenger
          r.fee += item.fee * amountPassenger
          r.feeService += item.feeService * amountPassenger
          r.tax += item.tax * amountPassenger
          r.total += item.total * amountPassenger
        })
        return [r]
      }

      return Object.values(res) // return ra array
    }

    // convert từ paxInfo là data của từng khách thành data của paxType
    function resolvePaxFareInfo(paxFareInfo) {
      const { paxInfo } = paxFareInfo
      const paxInfoGroup = groupBy(paxInfo, item => item.paxType)
      const res = Object.keys(paxInfoGroup).reduce((result, key) => {
        const amountPassenger = paxInfoGroup[key].length
        result[key] = {
          paxType: key,
          amountPassenger,
          netPrice: paxInfoGroup[key].reduce((netPrice, item) => netPrice + item.netPrice, 0) / amountPassenger,
          discount: paxInfoGroup[key].reduce((discount, item) => discount + item.discount, 0) / amountPassenger,
          discountPostpaid: paxInfoGroup[key].reduce((discountPostpaid, item) => discountPostpaid + item.discountPostpaid, 0) / amountPassenger,
          fee: paxInfoGroup[key].reduce((fee, item) => fee + ((isShowParentAgencyFee.value ? item?.parentFee : item.fee) || 0), 0) / amountPassenger,
          feeService: paxInfoGroup[key].reduce((feeService, item) => feeService + item.feeService, 0) / amountPassenger,
          tax: paxInfoGroup[key].reduce((tax, item) => tax + item.tax, 0) / amountPassenger,
          total: paxInfoGroup[key].reduce((total, item) => total + item.total, 0) / amountPassenger,
        }
        return result // object
      }, {})

      return Object.values(res) // return ra array
    }

    const sumTotalArrayTicket = computed(() => {
      if (!reservationsData.value?.paxFareInfoTicket) return null
      return reservationsData.value?.paxFareInfoTicket.map(paxFareInfoTicketItem => resolvePaxFareInfoTicket(paxFareInfoTicketItem).reduce((sum, item) => sum + (item.total * (item?.amountPassenger || 1)), 0))
    })

    const sumTotalArray = computed(() => {
      if (!reservationsData.value?.paxFareInfo) return null
      return reservationsData.value?.paxFareInfo.map(paxFareInfoItem => resolvePaxFareInfo(paxFareInfoItem).reduce((sum, item) => sum + (item.total * item.amountPassenger), 0))
    })

    const tableColumnsPriceQuotes = computed(() => {
      const arr = ['flight', 'paxType', 'fare', 'tax', 'feeService', 'agencyFee', 'total']
      return showServiceFee.value ? arr.filter(item => !item.includes('agencyFee')) : showAgencyfee.value ? arr.filter(item => !item.includes('feeService')) : arr.filter(item => !item.includes('feeService') && !item.includes('agencyFee'))
    })

    function getPricesByPriceQuotes(quoteList) {
      const result = []
      for (const priceQuote of quoteList) {
        result.push({
          paxType: priceQuote.appliedPassengers[0].paxType,
          flight: priceQuote.bookingInfos ? priceQuote.bookingInfos.map(item => `${item.departure}-${item.arrival}`).join(', ') : '',
          amount: priceQuote.appliedPassengers.length,
          fareBasisCode: priceQuote.fareInfos.map(fare => fare.fareBasisCode).join(', '),
          expirationTime: reservationsData.value?.source === '1G' ? convertISODateTime(priceQuote.expirationTime).timeDate : convertISODateTime(priceQuote.expirationTime).timeDate,
          lastDateToPurchase: convertISODateTime(priceQuote.lastDateToPurchase).timeDate,
          fee: (isShowParentAgencyFee.value ? priceQuote?.parentFee : priceQuote?.fee) || 0,
          fare: priceQuote.basePrice, // + (priceQuote?.discount ? priceQuote?.discount : 0),
          tax: priceQuote.totalTaxes, // - (priceQuote?.fee ? priceQuote?.fee : 0),
          total: priceQuote.totalPrice, // + (priceQuote?.discount ? priceQuote?.discount : 0) - (priceQuote?.fee ? priceQuote?.fee : 0),
        })
      }
      const res = {
        items: result,
        total: result.reduce((a, c) => a + (c.total * c.amount), 0),
      }
      return res
    }

    function getPricesByPriceQuotesTicket(quoteList) {
      const result = []
      for (const priceQuote of quoteList) {
        const amount = priceQuote.appliedPassengers?.length ?? 0
        if (amount) {
          result.push({
            paxType: priceQuote.appliedPassengers[0].paxType,
            flight: priceQuote.bookingInfos ? priceQuote.bookingInfos.map(item => `${item.departure}-${item.arrival}`).join(', ') : '',
            amount,
            fareBasisCode: priceQuote.fareInfos.map(fare => fare.fareBasisCode).join(', '),
            expirationTime: reservationsData.value?.source === '1G' ? convertISODateTime(priceQuote.expirationTime).timeDate : convertISODateTime(priceQuote.expirationTime).timeDate,
            lastDateToPurchase: convertISODateTime(priceQuote.lastDateToPurchase).timeDate,
            fare: priceQuote.basePriceTicket,
            feeService: priceQuote.feeService,
            fee: (isShowParentAgencyFee.value ? priceQuote?.parentFee : priceQuote?.fee) || 0,
            tax: priceQuote.totalTaxesTicket,
            total: priceQuote.totalPriceTicket,
          })
        }
      }
      const res = {
        items: result,
        total: result.reduce((a, c) => a + (c.total * c.amount), 0),
      }
      return res
    }

    function displayAirportCode(airportCode) {
      const airport = getAirportByAirportCode(airportCode)
      return airport?.name ? `${airport?.name} (${airportCode})` : airportCode
    }

    function resolveTrip(trip) {
      if (trip.length > 2) return trip.map(t => `${t.departure} - ${t.arrival}`).join(', ')
      return trip.map(t => `${displayAirportCode(t.departure)} - ${displayAirportCode(t.arrival)}`).join(', ')
    }

    // ANCHOR ancillary handle
    function disabledPaymentAncillary(bookingData) {
      if (!['VN1A', 'VN1A_MT'].includes(bookingData.source)) return true
      const listAncillary = bookingData.ancillaryServices.filter(item => !item.isPaid)
      if (!isEmpty(listAncillary)) return false
      return true
    }

    function openPaymentAncillaryServiceHandle(bookingData) {
      if (!['VN1A', 'VN1A_MT'].includes(bookingData.source)) return

      const bookingStatus = ['PAID', 'PARTIALLY_PAID'].includes(bookingData.status)

      if (!bookingStatus) {
        toastWarning({ title: 'Đối với vé chưa thanh toán, khi thanh toán vé sẽ tự động thanh toán dịch vụ thêm đi kèm !' })
        return
      }

      this.$bvModal.show('modal-modify-payment-ancillary-services')
    }

    // Tổng tiền giá vé bán
    const totalTicketPrice = computed(() => {
      if (reservationsData.value.paxFareInfoTicket && reservationsData.value.paxFareInfoTicket.length) {
        return sumTotalArrayTicket.value.reduce((a, c) => a + c, 0)
      }
      if (reservationsData.value.priceQuotes && !reservationsData.value.priceQuotes.length && reservationsData.value.historicalPriceQuotes && reservationsData.value.historicalPriceQuotes.length > 0) {
        return getPricesByPriceQuotesTicket(reservationsData.value.historicalPriceQuotes).total
      }
      if (reservationsData.value.priceQuotes && reservationsData.value.priceQuotes.length > 0) {
        return getPricesByPriceQuotesTicket(reservationsData.value.priceQuotes).total
      }
      return reservationsData.value.fareInfoTicket?.total || 0
    })

    // Tổng tiền dịch vụ mua thêm
    const totalAncillaryServicePrice = ref(0) // sync trong component ancillary-service

    // Tổng giá bán
    const totalAllTicket = computed(() => totalTicketPrice.value + totalAncillaryServicePrice.value)

    // Tổng tiền giá vé trả nhà cung cấp
    const totalPrice = computed(() => {
      if (reservationsData.value.paxFareInfo && reservationsData.value.paxFareInfo.length) {
        return sumTotalArray.value.reduce((a, c) => a + c, 0)
      }
      if (reservationsData.value.priceQuotes && !reservationsData.value.priceQuotes.length && reservationsData.value.historicalPriceQuotes && reservationsData.value.historicalPriceQuotes.length > 0) {
        return getPricesByPriceQuotes(reservationsData.value.historicalPriceQuotes).total
      }
      if (reservationsData.value.priceQuotes && reservationsData.value.priceQuotes.length > 0) {
        return getPricesByPriceQuotes(reservationsData.value.priceQuotes).total
      }
      return reservationsData.value.fareInfo?.total || 0
    })

    // Tổng giá trả nhà cung cấp
    const totalAll = computed(() => totalPrice.value + totalAncillaryServicePrice.value)

    // lợi nhuận
    const showBenefit = ref(false)
    const benefit = computed(() => totalAllTicket.value - totalAll.value)

    // check hiển thị cảnh báo kiểm tra lại phí dịch vụ (https://discord.com/channels/1054696448110903327/1296010896044326956/1296010900746141708)
    const isShowAlertCheckServiceFee = computed(() => reservationsData.value.source.includes('VN1A')
      && reservationsData.value.itineraries.some(itinerary => itinerary.some(segment => [segment.arrival.iataCode, segment.departure.iataCode].includes('VCS')))
      && (
        (reservationsData.value.flightType === 'OW' && reservationsData.value.itineraries.length > 1)
        || (reservationsData.value.flightType === 'RT' && reservationsData.value.itineraries.length > 2)
      ))

    return {
      resolveTrip,
      displayAirportCode,
      tableColumnsFareInfoTicket,
      tableColumnsPaxFareInfoTicket,
      formatCurrency,
      isVisibleCustomServiceFee,
      convertISODateTime,
      resolvePaxFareInfoTicket,
      showServiceFee,
      showAgencyfee,
      sumTotalArrayTicket,

      getPricesByPriceQuotesTicket,
      tableColumnsPriceQuotes,
      isShowToggleServiceFee,
      isPaidSegment,

      openPaymentAncillaryServiceHandle,
      disabledPaymentAncillary,
      fnAncillary,
      bookingSource,
      delay,
      getAirlineBySource,

      totalAncillaryServicePrice,
      totalAllTicket,
      totalAll,
      showBenefit,
      benefit,
      hideFeature,
      isShowParentAgencyFee,
      isShowAlertCheckServiceFee,
    }
  },
}
</script>
